@import '../core/variables';

@each $key, $val in $space-offsets {
  .sersi-p-#{$key} {
    padding: #{$val} !important;
  }
  .sersi-pt-#{$key} {
    padding-top: #{$val} !important;
  }
  .sersi-pr-#{$key} {
    padding-right: #{$val} !important;
  }
  .sersi-pb-#{$key} {
    padding-bottom: #{$val} !important;
  }
  .sersi-pl-#{$key} {
    padding-left: #{$val} !important;
  }
  .sersi-px-#{$key} {
    padding-right: #{$val} !important;
    padding-left: #{$val} !important;
  }
  .sersi-py-#{$key} {
    padding-top: #{$val} !important;
    padding-bottom: #{$val} !important;
  }

  .sersi-m-#{$key} {
    margin: #{$val} !important;
  }
  .sersi-mt-#{$key} {
    margin-top: #{$val} !important;
  }
  .sersi-mr-#{$key} {
    margin-right: #{$val} !important;
  }
  .sersi-mb-#{$key} {
    margin-bottom: #{$val} !important;
  }
  .sersi-ml-#{$key} {
    margin-left: #{$val} !important;
  }
  .sersi-mx-#{$key} {
    margin-right: #{$val} !important;
    margin-left: #{$val} !important;
  }
  .sersi-my-#{$key} {
    margin-top: #{$val} !important;
    margin-bottom: #{$val} !important;
  }
}