@import '../core/variables';

@mixin fill {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

@mixin visually-hidden() {
  .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    white-space: nowrap;

    outline: 0;

    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

@mixin _optionally-nest-content($selector-context) {
  @if ($selector-context == '') {
    @content;
  } @else {
    #{$selector-context} {
      @content;
    }
  }
}

@mixin high-contrast($target: active, $encapsulation: 'any') {
  @if ($target != 'active' and $target != 'black-on-white' and $target != 'white-on-black') {
    @error 'Unknown cdk-high-contrast value "#{$target}" provided. ' +
           'Allowed values are "active", "black-on-white", and "white-on-black"';
  }

  @if ($encapsulation != 'on' and $encapsulation != 'off' and $encapsulation != 'any') {
    @error 'Unknown cdk-high-contrast encapsulation "#{$encapsulation}" provided. ' +
           'Allowed values are "on", "off", and "any"';
  }

  // If the selector context has multiple parts, such as `.section, .region`, just doing
  // `.cdk-high-contrast-xxx #{&}` will only apply the parent selector to the first part of the
  // context. We address this by nesting the selector context under .cdk-high-contrast.
  @at-root {
    $selector-context: #{&};

    @if ($encapsulation != 'on') {
      // Note that if this selector is updated, the same change has to be made inside
      // `_overlay.scss` which can't depend on this mixin due to some infrastructure limitations.
      .cdk-high-contrast-#{$target} {
        @include _optionally-nest-content($selector-context) {
          @content;
        }
      }
    }

    @if ($encapsulation != 'off') {
      .cdk-high-contrast-#{$target} :host {
        @include _optionally-nest-content($selector-context) {
          @content;
        }
      }
    }
  }
}
