@import '../core/variables';

$selected-label-right-offset: 40px; 

.ng-select-control.ng-dropdown-panel {
  min-width: 150px;
}

.ng-select-dropdown-control {
  &.ng-select-searchable {
    .ng-select-container {
      min-height: $selected-label-right-offset;
    }
  }
}

.ng-select-control {
  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: $telus-accessible-light-green;
    &:hover, .ng-value-icon:hover {
      background-color: $telus-accessible-lighter-green;
    }
    .ng-value-icon.right {
      border-right: 1px solid $white-100 !important;
    }
  }
  &.ng-dropdown-panel {
    z-index: $dropdown-overlay-z-index;
  }
  &.ng-select-searchable {
    width: 100%;
    max-width: 100vw;
    .ng-select-container {
      height: auto;
      padding: $input-default-vertical-offset 0;
      .ng-value-container {
        .ng-input {
          position: absolute;
          padding: 0 $input-default-horizontal-offset;
          top: 50%;
          transform: translate(0, -50%);
          & > input {
            font-size: $input-font-size;
            font-family: $input-font-family;
          }
        }
      }
    }
    &.ng-invalid:not(.ng-untouched) .ng-select-container {
      border-color: $base-colors-error-highlight;
    }
  }
  &.sersi-multi-select {
    .ng-value-icon.right {
      border-left: none !important;
      border-right: 1px solid $white-100 !important;
      padding: 5px 6px !important;
    }
    .ng-select-container {
      min-height: 46px;
      height: auto;
      padding: 0;
      .text-tags {
        padding: 5px;
      }
    }
  }
  .select-min-width & {
    min-width: $select-control-min-width;
  }
}
