@use 'mixins';
@import 'components';
@import 'utils';
@import 'primeicons/primeicons.css';
@import '~material-icons/iconfont/outlined.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.scss';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import './icons/icons.scss';

html, body {
  margin: 0;
  padding: 0;
}

// For correct positioning of ng-select, body should have relative position
body {
  position: relative;
  color: $telus-thunder;
}

hr {
  height: 1px;
  background-color: $gray-300;
  border: none;
  margin: 10px 0;
}

// SERSI-LAYOUT
$drawer-content-z-index: 1;
$drawer-side-drawer-z-index: 2;
$drawer-backdrop-z-index: 3;
$drawer-over-drawer-z-index: 4;

@mixin drawer-stacking-context($z-index: 1) {
  position: relative;
  z-index: $z-index;
}

.sersi-drawer-container {
  @include drawer-stacking-context();

  box-sizing: border-box;
  display: block;

  overflow: visible;

  &[fullscreen] {
    @include mixins.fill();

    &.sersi-drawer-container-has-open {
      overflow: hidden;
    }
  }

  &.sersi-drawer-container-explicit-backdrop .sersi-drawer-side {
    z-index: $drawer-backdrop-z-index;
  }

  &.ng-animate-disabled, .ng-animate-disabled & {
    .sersi-drawer-backdrop, .sersi-drawer-content {
      transition: none;
    }
  }
}

.sersi-drawer-backdrop {
  @include mixins.fill();

  display: block;
  z-index: $drawer-backdrop-z-index;

  visibility: hidden;

  &.sersi-drawer-shown {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .sersi-drawer-transition & {
    transition: {
      duration: 400ms;
      timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      property: background-color, visibility;
    }
  }

  @include mixins.high-contrast(active, off) {
    opacity: 0.5;
  }
}

.sersi-drawer-content {
  @include drawer-stacking-context($drawer-content-z-index);

  display: block;
  height: 100%;
  overflow: visible;

  .sersi-drawer-transition & {
    transition: {
      duration: 400ms;
      timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      property: transform, margin-left, margin-right;
    }
  }
}

.sersi-drawer {
  $high-contrast-border: solid 1px currentColor;

  @include drawer-stacking-context($drawer-over-drawer-z-index);

  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  outline: 0;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translate3d(-100%, 0, 0);
  background-color: white;

  &, [dir='rtl'] &.sersi-drawer-end {
    @include mixins.high-contrast(active, off) {
      border-right: $high-contrast-border;
    }
  }

  [dir='rtl'] &, &.sersi-drawer-end {
    @include mixins.high-contrast(active, off) {
      border-left: $high-contrast-border;
      border-right: none;
    }
  }

  &.sersi-drawer-side {
    z-index: $drawer-side-drawer-z-index;
  }

  &.sersi-drawer-end {
    right: 0;
    transform: translate3d(100%, 0, 0);
  }

  [dir='rtl'] & {
    transform: translate3d(100%, 0, 0);

    &.sersi-drawer-end {
      left: 0;
      right: auto;
      transform: translate3d(-100%, 0, 0);
    }
  }

  &[style*='visibility: hidden'] {
    display: none;
  }
}

.sersi-drawer-inner-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid $nav-container-border;
}

.sersi-sidenav-fixed {
  position: fixed;
}

sersi-layout-toolbar {
  width: 100%;
}

.sersi-layout-feature {
  height: 100%;
  overflow: auto;
  display: block;

  .ps__rail-y {
    z-index: 9999999;
  }
}

// SERSI FORMLY TYPES

sersi-multi-select {
  p-multiselect > div.p-multiselect.p-component {
    width: 100%;
  }
}

sersi-phone-number {
  input {
    width: 100%;
  }
}

sersi-postal-code {
  input {
    width: 100%;
  }
}

sersi-single-select {
  p-dropdown > div.p-dropdown.p-component {
    width: 100%;
  }
}

sersi-zip-code {
  input {
    width: 100%;
  }
}

// MAIN USER OPTIONS

ifhms-boa-main-user-options,
ifhms-web-main-user-options {

  .p-dropdown .p-dropdown-label.p-placeholder {
    display: none;
  }

  .p-dropdown .p-dropdown-trigger {
    background-color: var(--purple-900);
    border-radius: 0;
    border: 0;

  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-dropdown {
    border: none;
  }

  p-dropdown > div > div.ng-trigger.ng-trigger-overlayAnimation.p-dropdown-panel.p-component.ng-star-inserted {
    top: 38px !important;
    left: -220px !important;
    width: 260px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 15px 20px;
  }

}

// DASHBOARD

ifhms-boa-dashboard {
  p-breadcrumb > div {
    border: none !important;
    padding: 0 !important;
  }
}
