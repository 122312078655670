@import '../core/variables';

@for $i from 1 through 6 {
  .grid-column-layout-#{$i} {
    display: grid;
    column-gap: $field-column-gap;
    column-count: $i;
    grid-template-columns: repeat($i, minmax(0, 1fr)) !important;
  }

  .col-span-#{$i} {
    grid-column: 1 / span $i;
  }
}


.formly-field-no-margin .p-field {
  margin: 0 !important;
}

form.disabled {
  background-color: $telus-background-grey !important;
}

.default-form-field-wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  &.positioned-error.field-error {
    margin: 15px 0;
    position: relative;
    .p-error {
      position: absolute;
      bottom: -18px;
    }
  }

  .sersi-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
  }

  .sersi-hint {
    margin-top: 3.5px;
    font-weight: 400;
  }

  .help-circle {
    font-size: 0.85rem;
    margin-left: 3px;
  }

  &.checkbox-field-wrapper {
    .p-checkbox {
      &, & .p-checkbox-box {
        width: 20px;
        height: 20px;
      }
    }
  }
}
