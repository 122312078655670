@import '../core/variables';

.text-break-word {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.text-default {
  color: $default-font-color;
}

.disabled-text {
  color: $base-colors-text-light;
}

.text-label-green {
  color: $green-300;
}

.text-label-red {
  color: $telus-red-negative;
}

.text-label-yellow {
  color: $yellow-400;
}

.text-label-purple {
  color: $purple-400;
}

.text-label-attention {
  color: $telus-orange-attention;
}

.h1 {
  font-size: 40px;
  line-height: 42px;
}

.h2 {
  font-size: 36px;
  line-height: 38px;
}

.h3 {
  font-size: 28px;
  line-height: 30px;
}

.h4 {
  font-size: 24px;
  line-height: 26px;
}

.h5 {
  font-size: 20px;
  line-height: 24px;
}

.h6 {
  font-size: 18px;
  line-height: 22px;
}

.subtitle-1 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

.subtitle-2 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.subtitle-3 {
  font-size: 17px;
  line-height: 26px;
  font-weight: bold;
}

.subtitle-4 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
}

.subtitle-5 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.subtitle-6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.text-xx-lg {
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
}

.text-x-lg {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.text-lg {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

// text regular
body, .text {
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
}

.text-sm {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.text-x-sm {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.caption-md {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.text-micro-1 {
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
}

.text-micro-2 {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
}

.text-color-default {
  color: $telus-thunder;
}

.h1, .h2, .h3, .h4 {
  font-family: $font-family-display;
}

.caption, .caption-md, .text-micro-1, text-micro-2 {
  font-family: $font-family-micro;
}