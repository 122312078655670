@import '../core/variables';

.common-confirmation-modal {
  .p-dialog-header {
    padding: 30px $confirmation-modal-box-padding $confirmation-modal-box-padding;
    .p-dialog-title {
      font-size: 26px;
      font-weight: 700;
    }
  }
  .p-dialog-content {
    padding: 0 $confirmation-modal-box-padding $confirmation-modal-box-padding;
  }
  .container-footer-buttons {
    margin-top: $confirmation-modal-box-padding;
  }

  .p-button {
    box-shadow: none !important;
  }
}

.p-dialog {
  .container-footer-buttons {
    margin-top: 20px;
    padding-top: 20px;
  }
}
