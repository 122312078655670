.tag-badge {
  z-index: auto;
  width: 77px;
  height: 28px;
  padding: 5px 10px;
  gap: 10px;
  color: var(--white);
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;

  &-small {
    @extend .tag-badge;
    display: inline-block;
    height: 24px;
    width: 62px;
    padding: 3px 3px;
    font-size: 10px;
    line-height: 18px;

  }
}

.primary {
  background: var(--bluegray-300);
}

.warning {
  background: var(--orange-500);
}

.success {
  background: var(--green-500);
}

.secondary {
  background: var(--gray-800);
}
  