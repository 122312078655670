@import '../core/variables';

$table-icon-box-size: $button-icon-size + $button-icon-offset * 2;

.button-height {
  height: 2.5rem;
}

.space-buttons {
  button:not(:last-child),
  p-button:not(:last-child) {
    margin-right: 20px;
  }
}

.p-button {
  &.button-pristine {
    font-size: inherit;
    padding: 0;
    box-shadow: none;
  }

  &.p-button-sm .button-icon {
    font-size: 18px;
  }

  padding: $default-button-padding;
  line-height: $default-button-line-height;
}

.p-button-filter {
  color: $gray-900;
  background: $telus-background-grey;
  border: 1px solid $telus-background-grey;

  &:enabled:hover {
    border-color: $telus-background-grey;
    background: $telus-background-grey;
    color: $purple-400;
  }

  .p-badge {
    color: $gray-900;
  }
}

.p-button.p-button-table-icon {
  padding: $button-icon-offset;
  width: $table-icon-box-size;
  height: $table-icon-box-size;
  border: 1px solid $telus-cloud !important;
  text-decoration: none;

  i {
    color: $telus-thunder;
    font-size: $button-icon-size;
  }
}

.p-button.p-button-outlined.p-button-danger {
  color: var(--red-500)!important;
}