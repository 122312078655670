// ICONS

.custom-icon {
  height: 1rem;
  width: 1rem;
}

.custom-arrow-down {
  background-image: url("/assets/icons/arrow-down-white.svg");
  background-position: center;
  background-repeat: no-repeat;
}
