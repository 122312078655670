@import 'core/variables';

$min-container-horizontal-offset: 30px;

.container-footer-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;

  .p-button:not(:first-child) {
    margin-left: 20px;
  }
}

.content-container {
  display: block;
  width: 100%;
  margin: 30px auto;
  max-width: $default-container-max-width;
  color: $telus-thunder;

  aside {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .article-container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  }

  @media (max-width: ($default-container-max-width + $min-container-horizontal-offset * 2)) {
    padding: 0 $min-container-horizontal-offset;
  }

  &.container-sm {
    max-width: $sm-container-max-width;
  }

  &.container-md {
    max-width: $md-container-max-width;
  }

  &.container-lg {
    max-width: $lg-container-max-width;

    @media (max-width: ($lg-container-max-width + $min-container-horizontal-offset * 2)) {
      padding: 0 $min-container-horizontal-offset;
    }
  }

  &.container-wide {
    max-width: 90%;
  }

  &.full-width {
    max-width: none;
    padding: 0 10px;
  }

  &.container-max {
    max-width: none;
    padding: 0;
  }


  &.sub-nav-container {
    max-width: none;
    padding: 0 10px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: $default-subnav-width 4fr;

    @media (max-width: 768px) {
      grid-template-columns: 4fr;
      grid-template-areas:
        "sidebar"
        "content"
    }
  }
}
