// Theme
@import 'scss/theme_variables';

// Spaces
$space-offsets: (
        'xs': 0.25rem, // 4px
        's': 0.5rem, // 8px
        'm': 0.75rem, // 12px
        'l': 1rem, // 16px
        'xl': 1.25rem, // 20px
        'xxl': 1.5rem, // 24px
        'xxl-2': 1.75rem, // 28px
        'xxl-3': 2rem, // 32px
        'xxl-4': 2.25rem, // 36px
        'xxl-5': 2.5rem, // 40px
        'xxl-6': 3rem, // 48px
        'xxl-7': 3.5rem, // 56px
        'xxl-8': 4rem, // 64px
        'xxl-9': 4.5rem, // 72px
        'xxl-10': 5rem, // 80px
        'xxl-11': 5.5rem, // 88px
        'xxl-12': 6rem, // 96px
        'xxl-13': 6.5rem, // 104px
        'xxl-14': 7rem, // 112px
        'xxl-15': 7.5rem, // 120px
        'xxl-16': 8rem, // 128px
        'xxl-17': 8.5rem, // 136px
        'xxl-18': 9rem, // 144px
        'xxl-19': 9.5rem, // 152px
        'xxl-20': 10rem, // 160px
        'xxl-21': 10.5rem, // 168px
        'xxl-22': 11rem, // 176px
        'xxl-23': 11.5rem, // 184px
        'xxl-24': 12rem, // 192px
        'xxl-25': 12.5rem // 200px
);

// Sizes
$size-blocks: (
        '10': 10px,
        '20': 20px,
        '30': 30px,
        '40': 40px,
        '50': 50px,
        '75': 75px,
        '100': 100px,
        '125': 125px,
        '150': 150px,
        '175': 175px,
        '200': 200px,
        '250': 250px,
        '300': 300px,
        '400': 400px,
        '500': 500px,
        '600': 600px,
        '10p': '10%',
        '15p': '15%',
        '20p': '20%',
        '25p': '25%',
        '50p': '50%',
        '75p': '75%',
);

// FONTS
/* Default */
$default-font-size: 1rem;
$default-line-height: 20px;
$default-font-family: 'HN for TELUS', sans-serif;
$font-family-display: 'HN for TELUS Display', sans-serif;
$font-family-micro: 'HN for TELUS Micro', sans-serif;
$default-font-color: $telus-thunder;
$font-base-path: "/assets";
/* Caption */
$caption-font-size: 0.875rem;
$caption-line-height: 18px;


// Buttons
$table-action-btn-size: 23px;
$default-button-padding: 10px 20px;
$default-button-line-height: 18px;
$button-icon-size: 20px;
$button-icon-offset: 8px;

// Inputs
$input-readony-border-color: #ced4da;
$input-font-size: 1rem;
$input-readonly-bg: $gray-100;
$input-font-family: $default-font-family;
$input-default-horizontal-offset: 12px;
$input-default-vertical-offset: 13px;
$input-sm-vertical-offset: 6px;
$input-sm-horizontal-offset: 6px;
$select-control-min-width: 160px;

// General controls
$default-control-border-radius: 2px;

// Header
$header-desktop-height: 160px;

// Container
$default-container-max-width: 1090px;
$lg-container-max-width: 1280px;
$md-container-max-width: 700px;
$sm-container-max-width: 370px;

// Nav
$default-subnav-width: 290px;
$toolbar-height: 60px;

// Formly
$field-column-gap: 1.25rem;


// Stack order
$toolbar-z-index: 1009;
$modal-z-index: 2203;
$dropdown-overlay-z-index: 5501;

// Screen sizes
$hd-screen-width: 1280px;
$hd-screen-height: 720px;

// Modals
$confirmation-modal-box-padding: 24px;

// Table
$table-default-width-md: 600px;

// Event
$event-back-button-size: 36px;
$event-header-gap: 10px;
$event-form-gap: 15px;
$event-container-horizontal-offset: 20px;
