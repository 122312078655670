@import '../core/variables';

.action-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;

  @extend .space-buttons;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-auto-columns {
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.section-description {
  font-size: $caption-font-size;
  line-height: $caption-line-height;
}
