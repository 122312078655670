@import '../core/variables';

.p-datatable.p-component {
  .p-datatable-table {
    table-layout: fixed;
  }

  .p-datatable-thead > tr > th {
    padding: 1rem 1rem;
  }

  .p-datatable-tbody > tr > td {
    padding: 0 1rem;
  }

  &.p-datatable-xl .p-datatable-tbody > tr > td {
    height: 56px;
  }

  &.p-datatable-lg .p-datatable-tbody > tr > td {
    height: 46px;
  }

  &.p-datatable-sm .p-datatable-tbody > tr > td {
    height: 36px;
  }

  &.p-datatable-offset {
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      &:first-child, &:last-child {
        box-sizing: content-box;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .p-table-size-md {
    min-width: $table-default-width-md;
  }

  .table-link {
    cursor: pointer;
    text-decoration: underline;
    color: $blue-500 !important;
  }

  .table-button {
    padding: 8px 10px;
    font-size: 12px;

    i {
      font-size: 20px;
    }
  }
}

th {
  &.cell-xxs {
    width: 40px;
  }

  &.cell-xs {
    width: 60px;
  }

  &.cell-sm {
    width: 100px;
  }

  &.cell-md {
    width: 150px;
  }

  &.cell-200 {
    width: 200px;
  }

  &.cell-lg {
    width: 33%;
  }
}

td > .p-button-text {
  padding: 0 !important;
  height: $table-action-btn-size !important;
  width: $table-action-btn-size !important;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .table-title {
    margin: 20px 0;
  }
}

.table-action-button {
  height: 2.5rem;
}

.table-disabled {
  opacity: 0.5;
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sersi-search-container {
    display: inline-block;
    margin-bottom: 10px;
  }
}
