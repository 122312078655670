@import 'core/variables';

$sersi-main-menu-width: 265px;
$sersi-sub-menu-level-width: 35px;

.sersi-content-container {
  color: $telus-thunder;
  width: 100%;
  height: calc(100vh - 60px);

  display: flex;
  flex-direction: row;

  aside {
    transition: all 0.5s ease-in-out;
  }

  article {
    transition: all 0.5s ease-in-out;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
  }
}
