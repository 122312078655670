@import '../core/variables';

textarea {
  resize: none;
}

.p-inputtext {
  &[readonly] {
    background-color: var(--background-grey, $gray-100);
    border: 1px solid $input-readony-border-color;
    cursor: default;
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: $telus-alabaster;
  }
}

.input-sm {
  &.p-inputtext {
    padding: $input-sm-vertical-offset $input-default-horizontal-offset;
  }

  .p-inputgroup-addon {
    padding: 0 !important;
  }

  &.p-calendar {

    .p-inputtext,
    .p-datepicker-trigger {
      padding: $input-sm-vertical-offset $input-default-horizontal-offset;
    }
  }

  .ng-select-control.ng-select-searchable .ng-select-container {
    padding: 8px 0;
  }

  sersi-single-select {
    .text-tags {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
  }
}

.input-xs {
  &.p-inputtext {
    padding: $input-sm-vertical-offset $input-default-horizontal-offset;
    width: 100px !important;
  }

  .ng-select-control.ng-select-searchable .ng-select-container {
    padding: 5px 0;
  }

  width: 120px !important;

  .sersi-multi-select {
    min-width: 120px !important;
  }

  .ng-select-control.sersi-multi-select .ng-select-container {
    height: 36px !important;
    min-height: 0 !important;
  }

  sersi-single-select {
    .text-tags {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
  }

  &.p-calendar {

    .p-inputtext,
    .p-datepicker-trigger {
      padding: $input-sm-vertical-offset $input-sm-horizontal-offset !important;
    }
  }
}

.number-with-buttons {
  background-color: $purple-400;
  border: 1px solid $purple-400;
  padding: 0 !important;

  &:hover {
    background: $telus-purple-hover !important;
    border: 1px solid $telus-purple-hover !important;
  }
}

.font-size-sm {
  font-size: 14px;
}

.header-calendar {
  width: 180px !important;
}

.header-calendar-sm {
  width: 160px !important;
}

.sersi-input-highlighted {

  .p-inputtext,
  textarea {
    background-color: $telus-input-highlight;
    border-color: $telus-orange-attention;
  }

  .ng-select-control.ng-select-searchable .ng-select-container {
    background-color: $telus-input-highlight;
    border-color: $telus-orange-attention;
  }
}

.sersi-input-wrapper {
  .input-text-icon-suffix {
    color: $telus-cloud;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);

    .sersi-input-highlighted & {
      color: $telus-orange-attention;
    }
  }
}

.ng-invalid {

  &.p-inputtext,
  &.ng-select-control.ng-select-searchable,
  &.p-inputwrapper,
  &.custom-ctrl-container {
    &:not(.ng-untouched) {
      &:not(.p-password-mask) {

        &:not(.ctrl-wrapper),
        .ng-select-container,
        &>.p-inputnumber>.p-inputtext,
        &>.p-password>.p-inputtext,
        .custom-ctrl-element>.p-component {
          border-color: $base-colors-error-highlight;
          color: $telus-red-negative;
          background-color: rgba(244, 67, 54, 0.15);
        }
      }
    }

    &:not(.ng-untouched) {
      &:not(.p-password-mask),
      .ng-select-container,
      &>.p-inputnumber>.p-inputtext,
      &>.p-password>.p-inputtext,
      .custom-ctrl-element>.p-component {
        border-color: $base-colors-error-highlight;
        color: $telus-red-negative;
        background-color: rgba(244, 67, 54, 0.15);
      }
    }
  }
}

.required-indicator {
  color: $telus-red-negative;
}