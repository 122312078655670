@import '../core/variables';

@each $key, $val in $size-blocks {
  .sersi-min-width-#{$key} {
    min-width: #{$val} !important;
  }
  .sersi-width-#{$key} {
    width: #{$val} !important;
  }
  .sersi-min-height-#{$key} {
    min-height: #{$val} !important;
  }
  .sersi-height-#{$key} {
    height: #{$val} !important;
  }
  .sersi-max-width-#{$key} {
    max-width: #{$val} !important;
  }
}

.min-height-50 {
  min-height: 50vh;
}


.sersi-max-width-1100 {
  max-width: 1100px !important;
}